import {ViolationOtherTargetName} from '../models/checkout/ViolationOtherTargetModel.model';
import {ViolationModel, ViolationSeverity} from '../models/checkout/Violation.model';

export const hasErrorViolations = (violations: ViolationModel[]): boolean => {
  return violations.some((violation) => violation.severity === ViolationSeverity.error);
};

export const calcTopThreeViolations = (
  violations: ViolationModel[],
  violationTargetNames: ViolationOtherTargetName[]
): ViolationModel[] => {
  const shouldDisplayViolation = (violation: ViolationModel): boolean => {
    const violationName = violation.target?.otherTarget?.name;
    return !!violationName && violationTargetNames.includes(violationName);
  };

  return [
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.error && shouldDisplayViolation(violation)
    ),
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.warning && shouldDisplayViolation(violation)
    ),
  ]
    .slice(0, 3)
    .reverse();
};

export const calcVisibleViolations = (violations: ViolationModel[]): ViolationModel[] => {
  const defaultViolations = calcTopThreeViolations(violations, [ViolationOtherTargetName.default]);
  const deliveryViolations = calcTopThreeViolations(violations, [ViolationOtherTargetName.delivery]);
  return [
    ...defaultViolations,
    ...deliveryViolations,
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.error && Boolean(violation.target?.lineItemTarget?.id)
    ),
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.warning && Boolean(violation.target?.lineItemTarget?.id)
    ),
  ];
};
