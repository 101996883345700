import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {
  createExpressCheckoutButtonsController,
  ExpressCheckoutButtonsController,
} from '@wix/ecom-platform-express-checkout-buttons';
import {StoresWidgetID} from '@wix/wixstores-client-core';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {NavigationService} from '../../services/checkout/NavigationService';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {BIService} from '../../services/checkout/BIService';
import {PaymentService} from '../../services/checkout/PaymentService';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ExpressCheckoutStore {
  private readonly controller: ExpressCheckoutButtonsController;

  constructor({
    controllerConfig,
    navigationService,
    checkoutService,
    paymentService,
    siteStore,
    flowAPI,
    biService,
    updateComponent,
  }: {
    controllerConfig: IWidgetControllerConfig;
    navigationService: NavigationService;
    checkoutService: CheckoutService;
    paymentService: PaymentService;
    siteStore: SiteStore;
    flowAPI: ControllerFlowAPI;
    biService: BIService;
    updateComponent: () => void;
  }) {
    this.controller = createExpressCheckoutButtonsController({
      getCurrency: () => checkoutService.checkout?.currency ?? siteStore.currency,
      getCheckoutId: /* istanbul ignore next */ () => Promise.resolve(checkoutService.checkout.id),
      controllerConfig,
      httpClient: flowAPI.essentials.httpClient,
      errorHandler: flowAPI.errorHandler,
      continueShoppingUrl: navigationService.externalContinueShoppingUrl,
      successUrl: navigationService.successUrl,
      onCancel: () => navigationService.reload(),
      onClick: () => biService.clickOnCheckoutWithEWallet(checkoutService.checkout),
      widgetId: StoresWidgetID.ECOM_PLATFORM_CHECKOUT,
      locale: flowAPI.translations.config.language,
      contentAppId: navigationService.checkoutContentAppId,
      contentComponentId: navigationService.checkoutContentComponentId,
      onPaymentMethodsInit: ({numberOfButtons}) => {
        paymentService.setExpressPaymentMethodsAmount(numberOfButtons);
        updateComponent();
      },
    });
  }

  public init(): void {
    void this.controller.init();
  }
}
