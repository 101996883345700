import {CheckoutSettingsModel} from '../models/checkout/checkoutSettings/CheckoutSettings.model';
import {CheckoutModel} from '../models/checkout/Checkout.model';
import {hasGiftCardItem} from './hasGiftCardItem';

export const shouldShowGiftCardSection = ({
  checkoutSettings,
  checkout,
  isGiftCardMixedCartEnabled,
}: {
  checkoutSettings: CheckoutSettingsModel;
  checkout: CheckoutModel;
  isGiftCardMixedCartEnabled?: boolean;
}): boolean => {
  return (
    checkoutSettings?.isGiftCardEnabled &&
    checkoutSettings?.isGiftCardSupported &&
    !checkout.customSettings?.hideGiftCard &&
    !checkout.hasSubscriptionItems &&
    (isGiftCardMixedCartEnabled || !hasGiftCardItem(checkout))
  );
};
