import {CheckoutModel} from '../models/checkout/Checkout.model';
import {hasGiftCardItem} from './hasGiftCardItem';

export const shouldShowCouponSection = (
  showCouponSetting: boolean,
  checkout: CheckoutModel,
  isGiftCardMixedCartEnabled?: boolean
) => {
  return (
    showCouponSetting &&
    !checkout.customSettings?.hideCoupon &&
    (isGiftCardMixedCartEnabled || !hasGiftCardItem(checkout))
  );
};
