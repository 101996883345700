import {NavigationService} from '../services/checkout/NavigationService';
import {CheckoutService} from '../services/checkout/CheckoutService';

export const isPickupFlow = ({
  navigationService,
  checkoutService,
}: {
  navigationService: NavigationService;
  checkoutService: CheckoutService;
}): boolean => {
  if (checkoutService.isPickupButShippingFlow) {
    return false;
  }

  return (
    (navigationService.isPickupFlow || navigationService.isPreselectedFlow) &&
    Boolean(checkoutService?.checkout?.selectedShippingOption?.isPickup)
  );
};

export const isShippingFlow = ({
  navigationService,
  checkoutService,
}: {
  navigationService: NavigationService;
  checkoutService: CheckoutService;
}): boolean => {
  return !isPickupFlow({navigationService, checkoutService}) && Boolean(checkoutService.checkout?.hasShippableItems);
};
