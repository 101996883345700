import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {isValidHttpUrl} from '../../utils/isValidHttpUrl';
import {ILink, MY_WALLET_APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {addWhiteListParamsToUrl} from '../../utils/whiteListParams';
import {getHeadlessUrl, NavigationType} from '@wix/wix-to-headless-redirect-client';
import {navigateToSuccessPageParams, navigateToThankYouPageParams} from '../../../types/checkoutApp.types';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {toMonitorError} from '../../utils/errorMonitor.utils';
import {SPECS} from '../../../common/constants';

export interface CheckoutAppSectionParams {
  checkoutId?: string;
  continueShoppingUrl?: string;
  cashierPaymentId?: string;
  isPickupFlow?: boolean;
  isPreselectedFlow?: boolean;
  origin?: string;
  originType?: string;
  successUrl?: string;
  theme?: string;
}

export class NavigationService {
  private readonly appSectionParams: CheckoutAppSectionParams;
  public readonly hasLeanFlowParam: boolean;
  public readonly currency?: string;
  private readonly siteStore: SiteStore;
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly flowAPI: ControllerFlowAPI;
  public isLoaded: boolean;
  public isMyWalletAppInstalled: boolean = false;
  private continueShoppingLink!: ILink;
  private fetchedEditCartUrl!: string;
  private readonly disableContinueShopping: boolean = false;
  private readonly contentAppId?: string;
  private readonly contentComponentId?: string;

  constructor({
    siteStore,
    controllerConfig,
    flowAPI,
  }: {
    siteStore: SiteStore;
    controllerConfig: IWidgetControllerConfig;
    flowAPI: ControllerFlowAPI;
  }) {
    this.isLoaded = false;
    this.siteStore = siteStore;
    this.controllerConfig = controllerConfig;
    this.appSectionParams = this.getAppSectionParams();
    this.currency = this.getCurrency();
    this.flowAPI = flowAPI;
    const url = new URL(this.siteStore.location.url);
    this.disableContinueShopping = url.searchParams.get('disableContinueShopping') === 'true';
    this.contentAppId = url.searchParams.get('contentAppId') ?? undefined;
    this.contentComponentId = url.searchParams.get('contentComponentId') ?? undefined;
    this.hasLeanFlowParam = url.searchParams.get('lean') === 'true';
  }

  public async load() {
    this.continueShoppingLink = await this.fetchContinueShoppingLink();
    this.fetchedEditCartUrl = await this.fetchEditCartUrl();
    this.isMyWalletAppInstalled = await this.fetchIsMyWalledAppInstalled();
    this.isLoaded = true;
  }

  private fetchIsMyWalledAppInstalled() {
    return this.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: MY_WALLET_APP_DEFINITION_ID,
      sectionId: 'my_wallet',
    });
  }

  public async navigateToHomepage() {
    const homepageLink = await this.siteStore.getHomepageLink();
    this.siteStore.navigateToLink(homepageLink);
  }

  public reload() {
    const url = new URL(this.siteStore.location.url);
    this.siteStore.navigateToLink(this.getInternalLink(url.toString()));
  }

  public login() {
    return this.controllerConfig.wixCodeApi.user.promptLogin({mode: 'login'});
  }

  public signup() {
    return this.controllerConfig.wixCodeApi.user.promptLogin({mode: 'signup'});
  }

  public getCheckoutCancelUrl() {
    const url = new URL(this.siteStore.location.url);
    url.searchParams.set('redirect', 'cancel');
    addWhiteListParamsToUrl(url, this.siteStore.location.url);
    return url.toString();
  }

  public getCheckoutErrorUrl() {
    const url = new URL(this.siteStore.location.url);
    url.searchParams.set('redirect', 'error');
    addWhiteListParamsToUrl(url, this.siteStore.location.url);
    return url.toString();
  }

  public async getThankYouPageUrlForPlaceOrder(isSubscription: boolean) {
    const objectType = isSubscription ? 'subscription' : 'order';
    if (this.successUrl && isValidHttpUrl(this.successUrl)) {
      return this.successUrl.replace('{objectType}', objectType);
    }
    const {url} = await this.siteStore.getSectionUrl(PageMap.THANKYOU);
    // istanbul ignore if
    if (!url) {
      return;
    }
    const orderIdPlaceholder = 'orderIdPlaceholder';
    const thankYouPageUrl = new URL(`${url}/${orderIdPlaceholder}`);
    const appSectionParams = JSON.stringify({
      objectType,
      origin: 'checkout',
      ...(this.externalContinueShoppingUrl ? {continueShoppingUrl: this.externalContinueShoppingUrl} : {}),
    });
    thankYouPageUrl.searchParams.set('appSectionParams', appSectionParams);
    addWhiteListParamsToUrl(thankYouPageUrl, this.siteStore.location.url);
    return thankYouPageUrl.toString().replace('orderIdPlaceholder', '{orderId}');
  }

  private async fetchContinueShoppingLink(): Promise<ILink> {
    const headlessUrl = getHeadlessUrl({
      query: this.siteStore.location.query,
      baseUrl: this.siteStore.location.baseUrl,
      navParams: {logicalName: NavigationType.ECOM_CONTINUE_BROWSING},
    });

    if (headlessUrl) {
      return this.getExternalLink(headlessUrl);
    }

    const continueShoppingUrl = this.appSectionParams.continueShoppingUrl;
    return continueShoppingUrl && isValidHttpUrl(continueShoppingUrl)
      ? this.getExternalLink(continueShoppingUrl)
      : this.siteStore.getHomepageLink();
  }

  private async fetchEditCartUrl(): Promise<string> {
    const headlessUrl = getHeadlessUrl({
      query: this.siteStore.location.query,
      baseUrl: this.siteStore.location.baseUrl,
      navParams: {logicalName: NavigationType.ECOM_CART},
    });

    if (headlessUrl) {
      return headlessUrl;
    }
    const {url} = await this.siteStore.getSectionUrl(PageMap.CART);
    return `${url!}?appSectionParams=${encodeURIComponent(JSON.stringify({origin: 'checkout'}))}`;
  }

  public navigateToContinueShopping(): void {
    return this.siteStore.navigateToLink(this.continueShoppingLink);
  }

  public async navigateOnFailure() {
    if (this.siteStore.experiments.enabled(SPECS.DontRedirectToSiteOnFailedFetch)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.flowAPI.controllerConfig.setProps({failedToFetch: true});
      return;
    }

    /* istanbul ignore next */
    if (!this.isLoaded) {
      await this.load();
    }
    this.navigateToContinueShopping();
  }

  public navigateToThankYouPage({orderId, isSubscription}: navigateToThankYouPageParams): Promise<void> | void {
    const objectType = isSubscription ? 'subscription' : 'order';
    if (this.successUrl && isValidHttpUrl(this.successUrl)) {
      return this.navigateToExternalSuccessPage({orderId, objectType});
    }
    return this.siteStore.navigate(
      {
        sectionId: PageMap.THANKYOU,
        queryParams: {
          objectType,
          origin: 'checkout',
          ...(this.externalContinueShoppingUrl
            ? {continueShoppingUrl: encodeURIComponent(this.externalContinueShoppingUrl)}
            : {}),
        },
        state: orderId,
      },
      true
    );
  }

  public navigateToExternalSuccessPage({orderId, objectType}: navigateToSuccessPageParams): void {
    const externalSuccessUrl = this.successUrl!.replace('{orderId}', orderId!).replace('{objectType}', objectType!);
    return this.siteStore.navigateToLink({
      sdkLink: {type: 'ExternalLink', target: '_top', url: externalSuccessUrl},
      url: externalSuccessUrl,
    });
  }

  public navigateToCart(cartId?: string): Promise<void> {
    return this.siteStore.navigate(
      {
        sectionId: PageMap.CART,
        queryParams: {
          objectType: 'order',
          origin: 'checkout',
        },
        state: cartId,
      },
      true
    );
  }

  private getCurrency() {
    return this.siteStore.location.query.currency;
  }

  private getAppSectionParams(): CheckoutAppSectionParams {
    const appSectionParams = this.siteStore.location.query.appSectionParams;
    if (!appSectionParams) {
      return {};
    }
    try {
      return JSON.parse(appSectionParams) as CheckoutAppSectionParams;
    } catch (e) {
      /* istanbul ignore next */
      this.flowAPI.errorMonitor.captureException(...toMonitorError(e));
      /* istanbul ignore next */
      return {};
    }
  }

  private getExternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_top', url}};
  }

  private getInternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_self', url}};
  }

  public get continueShoppingUrl() {
    return this.continueShoppingLink.url;
  }

  public get externalContinueShoppingUrl() {
    return this.appSectionParams.continueShoppingUrl;
  }

  public get isFastFlow() {
    return Boolean(this.appSectionParams?.cashierPaymentId);
  }

  public get isPickupFlow() {
    return Boolean(this.appSectionParams?.isPickupFlow);
  }

  public get isPreselectedFlow() {
    return Boolean(this.appSectionParams?.isPreselectedFlow);
  }

  public get origin() {
    return this.appSectionParams.origin;
  }

  public get originType() {
    return this.appSectionParams.originType;
  }

  public get editCartUrl() {
    return this.fetchedEditCartUrl;
  }

  public get checkoutId() {
    return this.appSectionParams.checkoutId;
  }

  public get cashierPaymentId() {
    return this.appSectionParams.cashierPaymentId;
  }

  public get successUrl() {
    return this.appSectionParams.successUrl;
  }

  public get theme() {
    return this.appSectionParams.theme;
  }

  public get isContinueShoppingEnabled() {
    return !this.disableContinueShopping;
  }

  public get checkoutContentAppId() {
    return this.contentAppId;
  }

  public get checkoutContentComponentId() {
    return this.contentComponentId;
  }
}
