import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {MemberService} from '../../services/checkout/MemberService';
import {MemberAddressActionBiParams, MemberStoreProps} from '../../../types/checkoutApp.types';
import {NavigationService} from '../../services/checkout/NavigationService';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {BIService} from '../../services/checkout/BIService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../../../common/constants';

export type MemberStoreConfig = {
  flowAPI: ControllerFlowAPI;
  memberService: MemberService;
  navigationService: NavigationService;
  checkoutService: CheckoutService;
  updateComponent: () => void;
  biService: BIService;
  siteStore: SiteStore;
};

export class MemberStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly memberService: MemberService;
  private readonly navigationService: NavigationService;
  private readonly checkoutService: CheckoutService;
  private readonly biService: BIService;
  private readonly siteStore: SiteStore;

  private readonly updateComponent: () => void;

  constructor({
    flowAPI,
    memberService,
    navigationService,
    checkoutService,
    updateComponent,
    biService,
    siteStore,
  }: MemberStoreConfig) {
    this.flowAPI = flowAPI;
    this.memberService = memberService;
    this.navigationService = navigationService;
    this.checkoutService = checkoutService;
    this.biService = biService;
    this.siteStore = siteStore;
    this.updateComponent = updateComponent;
  }

  private readonly reportEditAddressClicked = (params: MemberAddressActionBiParams) => {
    this.biService.memberClickOnEditAddress(params);
  };

  private readonly reportAddAddressClicked = (params: MemberAddressActionBiParams) => {
    this.biService.memberClickOnAddAddress(params);
  };

  public async init() {
    if (!this.flowAPI.experiments.enabled(SPECS.SupportMemberOnlyCheckout)) {
      await this.setIsMemberAreaAppInstalled();
    }

    if (this.memberService.isMember()) {
      await this.memberService.getCurrentUserEmail();
      await this.memberService.fetchAddressesInfo();
    }
  }

  public async setIsMemberAreaAppInstalled() {
    await this.memberService.setIsMemberAreaAppInstalled();
  }

  public toProps(): MemberStoreProps {
    return {
      isMember: this.memberService.isMember(),
      addressesInfo: this.memberService.addressesInfo,
      defaultAddressId: this.memberService.addressesInfo.defaultAddressId,
      isAddressesAppInstalled: this.memberService.isAddressesAppInstalled,
      isMemberAreaAppInstalled: this.memberService.isMemberAreaAppInstalled,
      currentUserEmail: this.memberService.currentUserEmail,
      reportEditAddressClicked: this.reportEditAddressClicked,
      reportAddAddressClicked: this.reportAddAddressClicked,
    };
  }
}
