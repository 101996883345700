// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {DEFAULT_ITEM_POLICY} from '../../../components/Checkout/constants';
import {PolicyFragment} from '../../../gql/graphql';

export class LineItemPolicy {
  public title: string;
  public content?: string;

  constructor({title, content}: PolicyFragment) {
    this.title = title && title !== '' ? title : DEFAULT_ITEM_POLICY;
    this.content = content ?? /* istanbul ignore next */ undefined;
  }
}
