import {SubscriptionOptionInfoFragment, WixPaymentApiPayV2SubscriptionFrequency} from '../../../gql/graphql';

export class SubscriptionModel {
  public name: string;
  public isAutoRenewal: boolean;
  public frequency: SubscriptionFrequency;
  public interval: number;
  public billingCycles: number;
  public startDate: string;
  public freeTrialInterval?: number;
  public freeTrialFrequency: SubscriptionFrequency;

  constructor({title, subscriptionSettings}: SubscriptionOptionInfoFragment) {
    this.name = title?.translated ?? title?.original ?? '';
    this.isAutoRenewal = Boolean(subscriptionSettings?.autoRenewal);
    this.frequency = toSubscriptionFrequency(subscriptionSettings?.frequency);
    this.interval = subscriptionSettings?.interval ?? 0;
    this.billingCycles = subscriptionSettings?.billingCycles ?? 0;
    this.startDate = subscriptionSettings?.startDate ?? '';
    this.freeTrialInterval = subscriptionSettings?.freeTrialPeriod?.interval ?? undefined;
    this.freeTrialFrequency = toSubscriptionFrequency(subscriptionSettings?.freeTrialPeriod?.frequency);
  }
}

export enum SubscriptionFrequency {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  UNDEFINED = 'UNDEFINED',
}

const toSubscriptionFrequency = (frequency?: WixPaymentApiPayV2SubscriptionFrequency | null) => {
  switch (frequency) {
    case WixPaymentApiPayV2SubscriptionFrequency.DAY:
      return SubscriptionFrequency.DAY;
    case WixPaymentApiPayV2SubscriptionFrequency.WEEK:
      return SubscriptionFrequency.WEEK;
    case WixPaymentApiPayV2SubscriptionFrequency.MONTH:
      return SubscriptionFrequency.MONTH;
    case WixPaymentApiPayV2SubscriptionFrequency.YEAR:
      return SubscriptionFrequency.YEAR;
    /* istanbul ignore next */ default:
      return SubscriptionFrequency.UNDEFINED;
  }
};
