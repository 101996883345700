import {CheckoutModel} from '../models/checkout/Checkout.model';
import {FormsService} from '../services/checkout/FormsService';

export function isEligibleForLeanFlow({
  checkout,
  hasLeanFlowParam,
  isMember,
  formsService,
}: {
  checkout: CheckoutModel;
  hasLeanFlowParam: boolean;
  isMember: boolean;
  formsService: FormsService;
}): boolean {
  return (
    checkout?.payNowTotalAfterGiftCard?.amount > 0 &&
    hasLeanFlowParam &&
    !checkout?.hasShippableItems &&
    !isMember &&
    !formsService.doesFormHaveExtendedField &&
    !formsService.doesFormHaveAdditionalInfo
  );
}
