import {ComponentState} from '@wix/delivery-section-checkout/types';

export class DeliverySectionService {
  private state!: ComponentState;
  public onCheckoutUpdated: () => Promise<void> = async () => {};

  public updateComponentState(state: ComponentState) {
    this.state = state;
  }

  public get componentState() {
    return this.state;
  }

  public setCheckoutUpdateCallback(fn: () => Promise<void>) {
    this.onCheckoutUpdated = fn;
  }
}
