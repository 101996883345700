import {Image, transformRESTImageToSDKImage} from '@wix/sdk-runtime/transformations/image';
import {transformRESTPageURLV2ToSDKPageURLV2} from '@wix/sdk-runtime/transformations/page-url-v2';
import {transformRESTAddressToSDKAddress} from '@wix/sdk-runtime/transformations/address';
import {AddressWithContact, Checkout, LineItem, ShippingInfo} from '@wix/ecom_checkout';
import {
  AddressWithContactFragment,
  CheckoutFragment,
  LineItemFragment,
  ShippingInfoFragment,
} from '../../../gql/graphql';

export function mapCheckoutToSDK(response: CheckoutFragment): Checkout {
  const {lineItems, shippingInfo, billingInfo, ...rest} = response;

  const updatedResponse = {
    ...rest,
    lineItems: mapLineItems(lineItems),
    shippingInfo: mapShippingInfo(shippingInfo!),
    billingInfo: mapBillingInfo(billingInfo!),
  };

  return replaceKeys(updatedResponse, {
    id: '_id',
  });
}

function replaceKeys<T, S>(obj: T, replaces: {[key: string]: string}): S {
  if (Array.isArray(obj)) {
    return obj.map((o) => replaceKeys(o, replaces)) as unknown as S;
  }
  if (obj && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [replaces[key] ? replaces[key] : key, replaceKeys(value, replaces)])
    ) as unknown as S;
  }
  return obj as unknown as S;
}

function mapLineItems(
  lineItems: (LineItemFragment | null)[] | null | undefined
): (LineItem & {id?: string | null}[]) | undefined {
  return lineItems?.map((lineItem) => ({
    ...lineItem,
    media: lineItem?.media ? transformRESTImageToSDKImage(lineItem.media as Image) : undefined,
    url: lineItem?.url ? transformRESTPageURLV2ToSDKPageURLV2(lineItem.url) : undefined,
  }));
}

function mapShippingInfo(shippingInfo?: ShippingInfoFragment): ShippingInfo | undefined {
  if (!shippingInfo) {
    return undefined;
  }

  const {shippingDestination, ...rest} = shippingInfo;

  return {
    ...rest,
    shippingDestination: shippingDestination
      ? {
          ...shippingDestination,
          address: shippingDestination.address
            ? transformRESTAddressToSDKAddress(shippingDestination.address)
            : undefined,
        }
      : undefined,
  } as ShippingInfo;
}

function mapBillingInfo(billingInfo?: AddressWithContactFragment): AddressWithContact | undefined {
  if (!billingInfo) {
    return undefined;
  }

  return {
    ...billingInfo,
    address: billingInfo.address ? transformRESTAddressToSDKAddress(billingInfo.address) : undefined,
  } as AddressWithContact;
}
